import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser';

const Terms = (props) => {
    const [details, setDetails] = useState(null);
    const { data } = props;

    useEffect(() => {
        if(data !== null) {
            const d = JSON.parse(data);
            setDetails(d);
        }
    },[]);

    return (
        <div className='terms'>
            <h3>Terms & Conditions</h3>
            {details !== null ? 
                <React.Fragment>
                    {parse(details.terms_and_conditions)}
                </React.Fragment>
            : null}
        </div>
    )
}

export default Terms