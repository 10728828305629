require('./bootstrap');

require('./components/public/Navs');
require('./components/public/PageToastr');
require('./components/public/Featured');
require('./components/public/Destinations');
require('./components/public/ImgCarousel');
require('./components/public/ImgGallery');
require('./components/public/PackageList');
require('./components/public/Social');
require('./components/public/Inquiry');
require('./components/public/Package');

require('./components/public/cruise/Index');
require('./components/public/cruise/Overview');
require('./components/public/cruise/Itinerary');
require('./components/public/cruise/Cabin');
require('./components/public/cruise/Inclusion');
require('./components/public/cruise/Additional');
