import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';

const PageToastr = () => {
    return (
        <div><Toaster/></div>
    )
}

if (document.getElementById('page-toastr')) {
    ReactDOM.render(<PageToastr />, document.getElementById('page-toastr'));
}