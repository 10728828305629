import React, { useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import { useCruiseList } from '../hooks/useCruiseList';
import { useDestinations } from '../../cruises/hooks/useDestinations';
import Select from 'react-select';
import { to12HFormat, toCurrency, toSysFormat } from '../../../helpers/Helper';

const Index = () => {
    const [destination, setDestination] = useState(null);

    const { cruises, get } = useCruiseList();
    const { kvOptions } = useDestinations();
    const keyRef = useRef(null);

    const handleChange = (val) => {
        setDestination(val !== null ? val.value : '');
    }

    const handleSubmit = async () => {
        const params = {
            destination,
            keywords: keyRef.current.value
        }
        await get(params);
    }

    return (
        <div className="container">
            <div className="list-filter">
                <form className='snow-form' autoComplete='off'>
                    <div className='form-group'>
                        <label htmlFor='keywords'>Keywords</label>
                        <input id='keywords' type='text' className='form-control' placeholder='keywords' ref={keyRef} />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='country'>Country</label>
                        <Select
                            id='country'
                            className='snow-select'
                            isClearable={true}
                            isSearchable={true}
                            onChange={handleChange}
                            options={kvOptions()}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    borderColor: '#c4c4c4',
                                    borderWidth: '2px',
                                    padding: '3px 5px',
                                    borderRadius: '3px',
                                    boxShadow: 'none',
                                    fontWeight: 400,
                                    ":hover": {
                                        borderColor: '#c4c4c4'
                                    }
                                })
                            }}
                        />
                    </div>

                    <button type='button' className='btn btn-primary' onClick={handleSubmit}> Search </button>
                </form>
            </div>

            <div className="list-items">
                {cruises.length > 0 && cruises.map(c => (
                    <div className="list-item" key={c.id}>
                        <div className="thumbnail"
                            style={{
                                backgroundImage: `url(/file/${c.media.length ? c.media[0] : 'default'}/thumb)`
                            }}
                        >&nbsp;</div>

                        <div className="details">
                            <a href={`/cruises/trip/${c.slug}`}>
                                <h6>{c.header}</h6>
                            </a>
                            <span className='country'>
                                <span className="material-symbols-outlined">
                                    directions_boat
                                </span>
                                {c.destination} | {c.cruise_name}
                            </span>
                            <p>
                                <span className="material-symbols-outlined">
                                    calendar_month
                                </span>
                                &nbsp; {toSysFormat(c.availability_start_date, 'MMM DD YYYY')} to {toSysFormat(c.availability_end_date, 'MMM DD YYYY')}
                            </p>
                        </div>

                        <div className="action">
                            <span className="list-price">
                                <sub>From</sub> {toCurrency(Math.min(...c.cabins.map(cc => cc.selling_price)))}
                                <span className='per-person'>*Price per person</span>
                            </span>
                            <a href={`/cruises/trip/${c.slug}`} className="btn btn-primary">View</a>
                        </div>
                    </div>

                ))}
            </div>
        </div>
    )
}

if (document.getElementById('cruise-list')) {
    ReactDOM.render(<Index />, document.getElementById('cruise-list'));
}