import * as yup from 'yup';

export const details = yup.object({
    id: yup
        .string()
        .max(50)
        .nullable(),
    package_type: yup
        .string()
        .required('Package type is required'),
    header: yup
        .string()
        .max(200)
        .required('Header is required'),
    subheader: yup
        .string()
        .max(200)
        .required('Subheader is required'),
    country_id: yup
        .number()
        .required('Country is required'),
    continent: yup
        .string()
        .required('Continent is required'),
    description: yup
        .string()
        .required('Description is required'),
});

export const terms = yup.object({
    terms_and_conditions: yup
        .string()
        .required('Terms and conditions is required'),
});

export const pricing = yup.object({
    has_pricing_table: yup
        .boolean()
        .default(false),
});

export const itinerary = yup.object({
    id: yup
        .string()
        .max(36)
        .nullable(),
    title: yup
        .string()
        .required('Title is required'),
    description: yup
        .string()
        .required('Description is required'),
    location: yup
        .string()
        .required('Location is required'),
    accomodation: yup
        .string()
        .nullable(),
    included_meals: yup
        .array(),
    optional_experiences: yup
        .string()
        .nullable(),
});

export const packageSchema = yup.object({
    id: yup
        .string()
        .max(50)
        .nullable(),
    title: yup
        .string()
        .max(200)
        .required('Title is required'),
    package_type: yup
        .string()
        .required('Package type is required'),
    country: yup
        .number()
        .required('Country is required'),
    description: yup
        .string()
        .required('Description is required'),
    itenerary: yup
        .string()
        .required('Itenerary is required'),
    inclusions: yup
        .string()
        .required('Inclusions is required'),
    keywords: yup
        .string()
        .required('Keywords is required'),
    selling_price: yup
        .number()
        .default(0)
        .required('Selling price is required'),
    promo_price: yup
        .number()
        .default(0)
        .nullable(),
    hotel: yup
        .string()
        .nullable(),
    hotel_link: yup
        .string()
        .nullable(),
    is_featured: yup
        .boolean()
        .default(false),
    is_published: yup
        .boolean()
        .default(false),
});

export const price = yup.object({
    id: yup
        .string()
        .max(36)
        .nullable(),
    package_id: yup
        .string()
        .required('Package is required'),
    start_date: yup
        .date()
        .required('Start date is required'),
    end_date: yup
        .date()
        .required('End date is required'),
    base_price: yup
        .number()
        .required('Price is required'),
    is_active: yup
        .boolean()
        .default(true)
});

export const inquiry = yup.object({
    package_id: yup
        .string()
        .required('Package is required'),
    addon_id: yup
        .array(),
    contact_name: yup
        .string()
        .required('Contact name is required'),
    contact_phone: yup
        .string()
        .required('Contact number is required'),
    contact_email: yup
        .string()
        .required('Contact email is required'),
    travel_start_date: yup
        .date()
        .required('Travel start date is required'),
    travel_end_date: yup
        .date()
        .required('Travel end date is required'),
    no_of_adults: yup
        .number()
        .required('Adult count is required'),
    no_of_children: yup
        .number()
        .required('Child count is required')
});