import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import ReactDOM from 'react-dom';
import "react-image-gallery/styles/css/image-gallery.css";

const ImgGallery = ({images}) => {
    const [media, setMedia] = useState([]);

    useEffect(() => {
        if(images !== null) {
            const imgs = images.split(',');
            const links = [];
            for(let i of imgs) {
                links.push({
                    original: `/file/${i}/thumb`,
                    thumbnail: `/file/${i}/thumb`,
                    originalHeight: '400px'
                });
            }

            setMedia(links);
        }
    },[images]);

    return <ReactImageGallery showThumbnails={true} infinite={true} items={media} lazyLoad={true} showNav={true} autoPlay={true} />;
}

export default ImgGallery;