import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useInclusions } from '../../cruises/hooks/useInclusions';

const Inclusion = ({data}) => {
    const [inclusions, setInclusions] = useState([]);
    const { incOptions } = useInclusions();

    useEffect(() => {
        if(data !== null) {
            const inc = data.split(',');
            const options = [];

            for(const i of inc){
                const option = incOptions.find(x => x.value === i);
                if(typeof(option) !== 'undefined') {
                    options.push(option);
                }
            }
            setInclusions(options);
        }
    },[data]);

    return (
        <React.Fragment>
            <h3>What's included</h3>

            <div className='grid-list'>
                {inclusions.length > 0 && inclusions.map(x => (
                    <div className='grid-item' key={x.value}>
                        <span className="material-symbols-outlined">
                            check
                        </span>
                        <div className='label-description'>
                            <h6>{x.label}</h6>
                            <p>{x.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

if (document.getElementById('cruise-inclusion')) {
    const data = document.getElementById('cruise-inclusion').getAttribute('data-inclusions');
    ReactDOM.render(<Inclusion data={data} />, document.getElementById('cruise-inclusion'));
}