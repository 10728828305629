import React, { useEffect, useState } from 'react'
import ImgGallery from '../ImgGallery'
import { ucfirst } from '../../../helpers/Helper';
import Itinerary from './Itinerary';
import Activities from './Activities';
import parse from 'html-react-parser';

const Overview = (props) => {
    const [details, setDetails] = useState(null);
    const [meals, setMeals] = useState([]);
    const { data } = props;    

    useEffect(() => {
        if(data !== null) {
            const d = JSON.parse(data);
            setDetails(d);

            const m = [];
            for(const [k,v] of Object.entries(d.attributes.meals)) {
                if(v > 0) {
                   m.push(`${v} ${ucfirst(k)}`);
                }
            }
            setMeals(m);
        }
    },[]);

    return (
        <React.Fragment>
            {details !== null ? (
                <React.Fragment>
                    <div className='overview'>
                        <ImgGallery images={details.media.join(',')} />
                        <div className='p-dt-row'>
                            <h3>{details.subheader}</h3>
                            {parse(details.description)}
                        </div>

                        <div className='p-dt-row'>
                            <div className='p-dt-col'>
                                <h5>Travel</h5>
                                <p>{details.attributes.days} days</p>
                            </div>
                            <div className='p-dt-col'>
                                <h5>Accomodation</h5>
                                <p>{details.attributes.nights} nights</p>
                            </div>
                        </div>

                        <div className='p-dt-row'>
                            <div className='p-dt-col'>
                                <h5>Meals</h5>
                                <p>{meals.join(',')}</p>
                            </div>
                            <div className='p-dt-col'>
                                <a href="#package-itinerary">
                                    View day-by-day trip itinerary
                                </a>
                            </div>
                        </div>
                    </div>

                    <Itinerary details={details}/>
                    <Activities details={details} />
                </React.Fragment>
            ) : null}
        </React.Fragment>
    )
}

export default Overview