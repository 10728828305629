import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import { usePackage } from '../packages/hooks/usePackage';
import SendInquiry from './modals/SendInquiry';

const Inquiry = ({id, csrf}) => {
    const [showModal, setShowModal] = useState(false);
    const { tourPackage } = usePackage(id);

    const handleShowInquiry = () => {
        setShowModal(true);
    }

    const handleCallback = () => {
        setShowModal(false);
    }

    return (
        <React.Fragment>
            <button className="btn btn-primary" onClick={handleShowInquiry} type='button'>Send Inquiry</button>
            {showModal ? <SendInquiry tour={tourPackage} callback={handleCallback} csrf={csrf} /> : null}
        </React.Fragment>
    )
}

if (document.getElementById('btn-inquiry')) {
    const id = document.getElementById('btn-inquiry').getAttribute('data-id');
    const csrf = document.getElementById('btn-inquiry').getAttribute('data-csrf');
    ReactDOM.render(<Inquiry id={id} csrf={csrf} />, document.getElementById('btn-inquiry'));
}