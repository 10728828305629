import moment from "moment-timezone";

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export const DEFAULT_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_FORMAT_SLASH = 'MM/DD/YYYY';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD hh:mm A';

export const APIFetch = async (url, method, payload = {}, token = null) => {
    const endpoint = process.env.MIX_API_HOST + url;
    
    const body = method === 'POST' ?
        JSON.stringify(payload) : null;

    const headers = {
        'Content-Type': 'application/json'
    }

    if(token !== null) {
        headers['X-CSRF-TOKEN'] = token;
    }

    const response = await fetch(endpoint, {
        method: method,
        headers: headers,
        body: body
    });

    const json = await response.json();
    
    return {
        is_success: json.status === 200,
        message: json.message,
        data: json.data
    }
} 

export const APIUpload = async (url, token, payload) => {
    const endpoint = process.env.MIX_API_HOST + url;

    const headers = {}
    headers['X-CSRF-TOKEN'] = token;

    const result = await fetch(endpoint, {
        method: 'POST',
        headers,
        body: payload
    });

    const json = await result.json();
    if(json.status === 200) {
        return json.data;
    }

    return false;
}

export const ellipsify = (text, max) => {
    const txt = text ?? '';
    return txt.substring(0, max-1) + (txt.length > max ? '...' : '');
}

export const defaultDate = () => {
    return moment.tz(moment(), DEFAULT_TIMEZONE).toDate();
}

export const today = (format = DEFAULT_FORMAT) => {
    return moment.tz(moment(), DEFAULT_TIMEZONE).format(format);
}

export const toSysFormat = (strDate, format = DEFAULT_FORMAT) => {
    if(strDate === null) return;

    const isValid = moment.utc(strDate);
    if(!isValid) return null;
    return moment(strDate).format(format);
}

export const to12HFormat = (strTime) => {
    return moment(strTime, 'HH:mm').format('hh:mm A');
}

export const toFromNow = (strDate) => {
    return moment.tz(strDate, DEFAULT_TIMEZONE).fromNow();
}

export const toHumanDate = (strDate, timezone = DEFAULT_TIMEZONE, format = DEFAULT_DATETIME_FORMAT) => {
    const isValid = moment.utc(strDate);
    if(!isValid.isValid()) {
        return <span>---</span>
    }

    return <span>{moment.utc(strDate).tz(timezone).format(format)}</span>;
}

export const toCurrency = (amount) => {
    const f = Intl.NumberFormat('en-us', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        currencyDisplay: 'narrowSymbol'
    });

    return f.format(Number(amount));
}

export const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export const ucfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}