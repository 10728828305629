import React, { useRef } from 'react'
import ReactDOM from 'react-dom';
import { useFeatured } from './hooks/useFeatured';
import { ellipsify, toCurrency } from '../../helpers/Helper';

const Featured = () => {
    const { tours } = useFeatured();

    const swiper = useRef(null);

    const handleScrollLeft = () => {
        swiper.current.scrollLeft -= 315;
    }

    const handleScrollRight = () => {
        swiper.current.scrollLeft += 315;
    }

    return (
        <div className="container">
            <div className="swiper-actions">
                {tours.length > 4 ? (
                    <React.Fragment>
                        <button className="swipe-left" onClick={handleScrollLeft}>
                            <span className="material-symbols-outlined">
                                chevron_left
                            </span>
                        </button>

                        <button className="swipe-right" onClick={handleScrollRight}>
                            <span className="material-symbols-outlined">
                                chevron_right
                            </span>
                        </button>
                    </React.Fragment>
                ) : null}
            </div>


            <ul className="featured-swiper" ref={swiper}>
                {tours.length > 0 && tours.map(x => (
                    <li className="featured-swiper-item" key={x.id}>
                        <div className='fsi-container'>
                            <div className='fsi-thumbnail'
                                style={{
                                    backgroundImage: `url(/file/${x.media[0]}/thumb)`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            ></div>
                            <h4 className='fsi-header'>
                                {ellipsify(x.header, 35) }
                            </h4>

                            <span className='fsi-price'>
                                <small>From</small> {toCurrency(x.selling_price)}
                                <span className='per-person'>*Price per person</span>
                            </span>
                            <a href={`/packages/trip/${x.slug}`} className='fsi-anchor-view btn btn-primary'>View</a>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

if (document.getElementById('featured-swiper')) {
    ReactDOM.render(<Featured />, document.getElementById('featured-swiper'));
}
