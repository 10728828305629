import React, { useEffect, useState } from 'react'
import { toCurrency, toSysFormat } from '../../../helpers/Helper';

const Pricing = (props) => {
    const [prices, setPrices] = useState([]);
    const { data } = props;

    useEffect(() => {
        if (data !== null) {
            const d = JSON.parse(data);
            setPrices(d.prices);
        }
    }, []);

    return (
        <div className='pricing'>
            <table className='table' cellSpacing={0}>
                <thead>
                    <tr>
                        <th>Trip Start</th>
                        <th>Trip End</th>
                        <th>Price</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {prices.length > 0 && prices.map((i, index) => (
                        <tr key={index}>
                            <td>
                                <small>{toSysFormat(i.start_date, 'dddd')}</small><br/>
                                <span>{toSysFormat(i.start_date, 'MMM DD YYYY')}</span>
                            </td>
                            <td>
                                <small>{toSysFormat(i.end_date, 'dddd')}</small><br/>
                                <span>{toSysFormat(i.end_date, 'MMM DD YYYY')}</span>
                            </td>
                            <td>{toCurrency(i.base_price)}</td>
                            <td>{i.is_active ? 'Available' : 'Sold out'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Pricing