import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { to12HFormat } from '../../../helpers/Helper';

const Itinerary = ({data}) => {
    const [itinerary, setItinerary] = useState([]);

    useEffect(() => {
        if(data !== null) {
            const p = JSON.parse(data);
            setItinerary(p);
        }
    },[data]);

    return (
        <React.Fragment>
            <h3>Itinerary</h3>

            <div className='itinerary-items'>
                {itinerary.length > 0 && itinerary.map(x => (
                    <div className='itinerary-item' key={x.day_sequence}>
                        <div className='itinerary-item-thumbnail'>
                            <div 
                                className="thumbnail"
                                style={{
                                    backgroundImage: `url(/file/${x.media[0]}/thumb)`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover'
                                }}
                            ></div>
                        </div>

                        <div className='itinerary-item-header'>
                            <p>DAY {x.day_sequence}</p>
                            <h6>{ x.title }</h6>
                            <p>{ x.location }</p>
                            <p>{`${to12HFormat(x.hours_start)} - ${to12HFormat(x.hours_end)}`}</p>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

if (document.getElementById('cruise-itinerary')) {
    const data = document.getElementById('cruise-itinerary').getAttribute('data-itinerary');
    ReactDOM.render(<Itinerary data={data} />, document.getElementById('cruise-itinerary'));
}