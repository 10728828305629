import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

const ImgCarousel = ({images}) => {
    const [media, setMedia] = useState([]);

    useEffect(() => {
        if(images !== null) {
            setMedia(images.split(','));
        }
    },[images]);

    return (
        <Carousel 
            showArrows={true} 
            showIndicators={false} 
            showThumbs={false} 
            autoPlay={true} 
            infiniteLoop={true}
            interval={2000}
            centerSlidePercentage={80}
        >
            {media.map(x => (
                <div key={x}>
                    <img src={`/file/${x}/thumb`} />
                </div>
            ))}
        </Carousel>
    )
}

if (document.getElementById('image-carousel')) {
    const images = document.getElementById('image-carousel').getAttribute('data-images');
    ReactDOM.render(<ImgCarousel images={images} />, document.getElementById('image-carousel'));
}