import React from 'react';
import ReactDOM from 'react-dom';
import { useDestinations } from './hooks/useDestinations'

const Destinations = () => {
    const { destinations } = useDestinations();

    return (
        <div className='container'>
            <div className='square-tiles'>
                {destinations.length > 0 && destinations.map(x => (
                    <div className='tile' key={x.id}>
                        <div className='box'>
                            <a href={`/packages/list?type=all&country=${x.id}`} className='image-overlay'
                                style={{
                                    backgroundImage: `url(/file/${x.media}/thumb)`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                            </a>

                            <div className='generic-text-container'>
                                <p className='country'>
                                    {x.country}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

if (document.getElementById('destination-tiles')) {
    ReactDOM.render(<Destinations />, document.getElementById('destination-tiles'));
}