import React, { useEffect, useState } from 'react'
import Modal from '../../common/Modal'
import { useSendPackageInquiry } from '../hooks/useSendPackageInquiry';
import FormInput from '../../form/FormInput';
import MultiSelect from '../../form/MultiSelect';
import TextArea from '../../form/TextArea';

const SendInquiry = (props) => {
    const [disabled, setDisabled] = useState(false);

    const { tour, callback } = props;
    const { formik, handleOnChange } = useSendPackageInquiry(props, setDisabled);

    return (
        <Modal>
            <div className="inquiry-modal">
                <div className="inquiry-header">
                    <h3>Send Inquiry</h3>
                    <button className='btn-close' onClick={callback}>
                        <span className="material-symbols-outlined">close</span>
                    </button> 
                </div>

                <form autoComplete='off' className='snow-form' method='post' onSubmit={formik.handleSubmit}>
                    <div className='col'>
                        <div className='form-group'>
                            <label className='form-label'>Package</label>
                            <h5>{tour.header}</h5>
                        </div>
                    </div>

                    <div className='col'>
                        <FormInput id='contact_name' label='Contact Name' formik={formik} isRequired={true} />
                    </div>

                    <div className='col'>
                        <FormInput id='contact_phone' label='Contact Phone' formik={formik} isRequired={true} />
                        <FormInput id='contact_email' label='Contact Email' formik={formik} isRequired={true} />
                    </div>
                    
                    <div className='col'>
                        <FormInput type='date'id='travel_start_date' label='Travel Start Date' formik={formik} isRequired={true} />
                        <FormInput type='date'id='travel_end_date' label='Travel End Date' formik={formik} isRequired={true} />
                    </div>

                    <div className='col'>
                        <FormInput type='number'id='no_of_adults' label='No. of adults' formik={formik} isRequired={true} />
                        <FormInput type='number'id='no_of_children' label='No. of child' formik={formik} isRequired={true} />
                    </div>

                    <div className='col'>
                        <TextArea  
                            id='message'
                            label='Message'
                            onChange={(v) => handleOnChange('message', v)}
                            hasError={(Boolean(formik.touched.message) && Boolean(formik.errors.message))}
                            errorMsg={formik.errors.message}
                            isRequired={false}
                            rows={3}
                        />
                    </div>

                    <button type='submit' className='btn btn-primary' disabled={disabled}>Send Inquiry</button>
                </form>
            </div>
        </Modal>
    )
}

export default SendInquiry