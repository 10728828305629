import React from 'react'

export const useCabins = () => {
    const options = [
        {
            value: 'inside',
            label: 'Inside',
            description: 'Compact interior windowless rooms, recommended if you plan to spend most time outside the room.'
        },
        {
            value: 'oceanview',
            label: 'Oceanview',
            description: 'Cozy exterior rooms with a window, recommended for its natural light and views of the passing scenery.'
        },
        {
            value: 'balcony',
            label: 'Balcony',
            description: 'Larger rooms, recommended if you want to unwind in a private outdoor space.'
        },
        {
            value: 'suite',
            label: 'Suite',
            description: 'Luxurious rooms, recommended for its multiple living spaces, exclusive access and services.'
        },
    ];

    const kvOptions = () => {
        const kvo = [];

        for(let op of options) {
            kvo.push({
                value: op.value,
                label: op.label
            });
        }

        return kvo;
    }


    return { options, kvOptions }
}