import React, { Suspense, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import { usePackageList } from './hooks/usePackageList';
import { ellipsify, toCurrency } from '../../helpers/Helper';
import { useDestinations } from './hooks/useDestinations';
import Select from 'react-select';
import Loading from './Loading';
import parse from 'html-react-parser'

const PackageList = ({ pType, country }) => {
    const [countryOption, setCountryOption] = useState(null);

    const { packages, get } = usePackageList(pType, country);
    const { kvOptions } = useDestinations();
    const keyRef = useRef(null);

    const handleChange = (val) => {
        setCountryOption(val !== null ? val.value : '');
    }

    const handleSubmit = async () => {
        const params = {
            countryOption,
            keywords: keyRef.current.value
        }
        await get(params);
    }

    return (
        <div className="container">
            <div className="list-filter">
                <form className='snow-form' autoComplete='off'>
                    <div className='form-group'>
                        <label htmlFor='country'>Country</label>
                        <Select
                            id='country'
                            className='snow-select'
                            isClearable={true}
                            isSearchable={true}
                            onChange={handleChange}
                            options={kvOptions()}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    borderColor: '#c4c4c4',
                                    borderWidth: '2px',
                                    padding: '3px 5px',
                                    borderRadius: '3px',
                                    boxShadow: 'none',
                                    fontWeight: 400,
                                    ":hover": {
                                        borderColor: '#c4c4c4'
                                    }
                                })
                            }}
                        />
                    </div>

                    <button type='button' className='btn btn-primary' onClick={handleSubmit}> Search </button>
                </form>
            </div>

            <Suspense fallback={<Loading />}>
                <div className="list-items">
                    {packages.length > 0 && packages.map(p => (
                        <div className="list-item" key={p.id}>
                            <div className="thumbnail"
                                style={{
                                    backgroundImage: `url(/file/${p.media.length ? p.media[0] : 'default'}/thumb)`
                                }}
                            >&nbsp;</div>

                            <div className="details">
                                <a href={`/packages/trip/${p.slug}`}>
                                    <h6>{ellipsify(p.header, 65)}</h6>
                                </a>
                                <span className='country'>
                                    <span className="material-symbols-outlined">
                                        explore
                                    </span>
                                    {p.country.short_name}
                                </span>
                            </div>

                            <div className="action">
                                <span className="list-price">
                                    <sub>From</sub> {toCurrency(p.selling_price)}
                                    <span className='per-person'>*Price per person</span>
                                </span>
                                <a href={`/packages/trip/${p.slug}`} className="btn btn-primary">View</a>
                            </div>
                        </div>
                    ))}
                </div>
            </Suspense>
        </div>
    )
}

if (document.getElementById('package-list')) {
    const pType = document.getElementById('package-list').getAttribute('data-type');
    const country = document.getElementById('package-list').getAttribute('data-country');
    ReactDOM.render(<PackageList pType={pType} country={country} />, document.getElementById('package-list'));
}