import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

const Itinerary = (props) => {
    const [items, setItems] = useState([]);
    const [active, setActive] = useState(0);
    const { details } = props;

    useEffect(() => {
        setItems(details.itinerary);
    }, []);

    const onClickExpand = (day) => {
        setActive(day);

        const activeDay = document.getElementById(`day_${day}`);
        activeDay.scrollIntoView();
    }

    return (
        <div className='itinerary' id='package-itinerary'>
            <h3>ITINERARY</h3>

            {items.map(i => (
                <div className='itinerary-item' key={i.day_sequence} id={`day_${i.day_sequence}`}>
                    <div className='itinerary-item-head'>
                        <p>
                            {`Day ${i.day_sequence}`}
                            <button className='toggle-expand' onClick={() => onClickExpand(i.day_sequence)}>
                                <span className="material-symbols-outlined">expand_more</span>
                            </button>
                        </p>
                        <h4>{i.title}</h4>
                        <p>{i.location}</p>
                    </div>
                    <div className={`itinerary-item-body ${i.day_sequence === active ? 'active' : ''}`}>
                        {i.media.length > 0 ? (
                            <div className='thumbnail' style={{
                                backgroundImage: `url(/file/${i.media[0]}/thumb)`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'contain'
                            }}>

                            </div>
                        ) : null}

                        {parse(i.description)}
                        <p>
                            <span className="material-symbols-outlined">
                                hotel
                            </span>
                            {i.accomodation}
                        </p>

                        {i.included_meals.length > 0 ? (
                            <p>
                                <span className="material-symbols-outlined">
                                    restaurant
                                </span>
                                {i.included_meals.join(', ')}
                            </p>
                        ) : null}

                        {i.optional_experiences !== null ? (
                            <p className='optional_exp'>
                                <strong>Optional Experience: </strong>
                                {parse(i.optional_experiences)}
                            </p>
                        ) : null}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Itinerary