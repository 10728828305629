import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import ImgGallery from '../ImgGallery';
import Cabin from './Cabin';

const Overview = ({details}) => {
    const [cruise, setCruise] = useState(null);

    useEffect(() => {
        if(details !== null) {
            const d = JSON.parse(details);
            setCruise(d);
        }
    },[details]);

    return (
        <React.Fragment>
            {cruise !== null ? (
                <React.Fragment>
                    <ImgGallery images={cruise.media.join(',')} />
                    <Cabin data={cruise.rooms} />
                </React.Fragment>
            ) : null}
        </React.Fragment>
    )
}

if (document.getElementById('cruise-overview')) {
    const details = document.getElementById('cruise-overview').getAttribute('data-details');
    ReactDOM.render(<Overview details={details} />, document.getElementById('cruise-overview'));
}