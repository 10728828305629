import React, { useEffect, useState } from 'react'
import { toCurrency } from '../../../helpers/Helper';

const CabinItem = (props) => {
    const [iniVal, setIniVal] = useState(null);
    const { type, data } = props;
    
    useEffect(() => {
        setIniVal(data[0]);
    },[data]);

    const handleSelectClick = () => {
        console.log('Function under construction');
    }

    return (
        <div className='cabin-item' >
            <div className='cabin'>
                <div 
                    className='thumbnail'
                    style={{
                        backgroundImage: `url(/file/${iniVal?.media[0]}/thumb)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover'
                    }}
                ></div>

                <div className='details'>
                    <h5>{type.label}</h5>
                    <p>{ type.description }</p>
                    
                    <div className='pax_price'>
                        <span className='max_pax'>
                            <span className="material-symbols-outlined">
                                group
                            </span> {iniVal?.max_person}
                        </span>

                        <div className='price'>
                            <small>From</small> {toCurrency(iniVal?.selling_price)}
                        </div>
                    </div>
                    <button onClick={handleSelectClick} type='button' className='btn btn-primary btn-small'>Select</button>
                </div>
            </div>
        </div>
    )
}

export default CabinItem