import React, { useEffect, useState } from 'react'
import { APIFetch } from '../../../helpers/Helper';

export const usePackageList = (pType, country) => {
    const [packages, setPackages] = useState([]);

    const get = async (params) => {
        let url = `/packages/list?type=${pType}&country=${country}`;

        if(typeof(params) !== 'undefined') {
            for(let i in params) {
                url = `${url}&${i}=${params[i]}`;
            }
        }

        const result = await APIFetch(url, 'GET');
        if(result.is_success) {
            setPackages(result.data);
        }
    }

    useEffect(() => {
        get();
    },[]);

    return { packages, get }
}