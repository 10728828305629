import React, { useEffect, useState } from 'react'
import { APIFetch } from '../../../helpers/Helper';

export const useFeatured = () => {
    const [tours, setTours] = useState([]);

    const get = async() => {
        const result = await APIFetch('/packages/featured', 'GET');
        if(result.is_success) {
            setTours(result.data);
        }
    }

    useEffect(() => {
        get();
    },[]);

    return { tours }
}