import React, { useEffect, useState } from 'react'
import { APIFetch } from '../../../helpers/Helper';

export const useCruiseList = () => {
    const [cruises, setCruises] = useState([]);

    const get = async (params) => {
        let url = `/cruises/list?type=`;

        if(typeof(params) !== 'undefined') {
            for(let i in params) {
                url = `${url}&${i}=${params[i]}`;
            }
        }

        const result = await APIFetch(url, 'GET');
        if(result.is_success) {
            setCruises(result.data);
        }
    }

    useEffect(() => {
        get();
    },[]);

    return { cruises, get }
}