import React, { useEffect, useState } from 'react'
import { APIFetch } from '../../../helpers/Helper';

export const usePackage = (id) => {
    const [tourPackage, setTourPackage] = useState({});

    const getPackage = async () => {
        if(Object.keys(tourPackage).length === 0) {    
            const result = await APIFetch(`/packages/${id}/get`, 'GET');
            if(result.is_success) {
                if(result.data !== null) {
                    setTourPackage(result.data);
                    return;
                }
                setTourPackage({...tourPackage, id:id});
            }
        }
    }

    useEffect(() => {
        getPackage();
    },[id]);

    return { tourPackage, setTourPackage }
}