import { useFormik } from 'formik';
import React from 'react'
import { inquiry } from '../../../schema/packageSchema';
import { APIFetch } from '../../../helpers/Helper';
import toast from 'react-hot-toast';

export const useSendPackageInquiry = (props, setDisabled) => {
    const { tour, callback, csrf } = props;

    const formik = useFormik({
        initialValues: {
            package_id: tour.id,
            addon_id: [],
            contact_name: '',
            contact_phone: '',
            contact_email: '',
            travel_start_date: '',
            travel_end_date: '',
            no_of_adults: 1,
            no_of_children: 0,
            message: ''
        },
        validationSchema: inquiry,
        onSubmit: async (values) => {
            setDisabled(true);
            const result = await APIFetch(`/packages/${values.package_id}/inquiry`,'POST', values, csrf);
            if(result.is_success){
                toast.success('Your inquiry has been sent');
            }else {
                toast.error('Unable to send inquiry at the moment');
            }
            setDisabled(false);
            callback();
        }
    });

    const handleOnChange = (field, value) => {
        formik.setFieldValue(field, value);
    }

    return { formik, handleOnChange }
}