import React, { useEffect, useState } from 'react'
import { APIFetch } from '../../../helpers/Helper';

export const useDestinations = () => {
    const [destinations, setDestinations] = useState([]);

    const get = async() => {
        const result = await APIFetch('/packages/destinations', 'GET');
        if(result.is_success) {
            setDestinations(result.data);
        }
    }

    const kvOptions = () => {
        return destinations.map(x => {
            return {
                label: x.country,
                value: x.id
            }
        })
    }

    useEffect(() => {
        get();
    },[]);

    return { destinations, kvOptions }
}