import React from 'react'
import ReactDOM from 'react-dom';
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share';

const Social = ({slug, title, thumbnail}) => {
    return <React.Fragment>
        <FacebookShareButton
            url={`https://snowphiltravel.com/packages/trip/${slug}`}
            title={title}
            media={thumbnail}
            hashtag="#snowphiltravelandtours"
        >
            <FacebookIcon size={32} round />
        </FacebookShareButton>

        <LinkedinShareButton
            url={`https://snowphiltravel.com/packages/trip/${slug}`}
            title={title}
            media={thumbnail}
            hashtag="#snowphiltravelandtours"
        >
            <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <TwitterShareButton
            url={`https://snowphiltravel.com/packages/trip/${slug}`}
            title={title}
            media={thumbnail}
            hashtag="#snowphiltravelandtours"
        >
            <TwitterIcon size={32} round />
        </TwitterShareButton>

        <WhatsappShareButton
            url={`https://snowphiltravel.com/packages/trip/${slug}`}
            title={title}
            media={thumbnail}
            hashtag="#snowphiltravelandtours"
        >
            <WhatsappIcon size={32} round />
        </WhatsappShareButton>
    </React.Fragment>
}

if (document.getElementById('package-social')) {
    const slug = document.getElementById('package-social').getAttribute('data-slug');
    const title = document.getElementById('package-social').getAttribute('data-title');
    const thumbnail = document.getElementById('package-social').getAttribute('data-thumbnail');
    ReactDOM.render(<Social slug={slug} title={title} thumbnail={thumbnail} />, document.getElementById('package-social'));
}