import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import logo from '../../../img/logo.png'

const whatsapp = "https://api.whatsapp.com/send/?phone=15404877629";

const Navs = ({ phone, email }) => {
    const [showMenu, setShowMenu] = useState(false);

    const menu = [
        {
            url: '/',
            label: 'Home'
        },
        {
            url: '/packages/list?type=custom',
            label: 'Deals'
        },
        {
            url: '/packages/list?type=standard',
            label: 'Tours'
        },
        {
            url: '/cruises/list',
            label: 'Cruise'
        },
        {
            url: '/#snow-about',
            label: 'About Us'
        },
        {
            url: '/#snow-contact',
            label: 'Contact Us'
        },
    ];

    const handleToggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const handleClose = () => {
        setShowMenu(false);
    }

    return (
        <nav id="main-nav">
            <div className="brand">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" />
                    <div className="company">
                        <h5>SNOWPHIL</h5>
                        <h6>Travel & Tours</h6>
                    </div>
                </a>
            </div>

            <button className="navbar-toggler" type="button" onClick={handleToggleMenu}>
                <span className="material-symbols-outlined">
                    menu
                </span>
            </button>

            <div className='inline-menu'>
                <ul className="navbar-nav">
                    {menu.map(a => {
                        return <li key={a.label}><a href={a.url}>{a.label}</a> </li>
                    })}
                </ul>
            </div>

            {showMenu ? (
                <div className='overlay-menu'>
                    <button className="navbar-toggler" type="button" onClick={handleClose}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </button>

                    <ul className="navbar-nav">
                        {menu.map(a => {
                            return <li key={a.label}><a href={a.url}>{a.label}</a> </li>
                        })}
                    </ul>

                    <h5>Contact Information</h5>
                    <ul className='navbar-nav'>
                        <li>
                            <a href={whatsapp} target="_blank" className="contact-info">
                                <span className="material-symbols-outlined">
                                    call
                                </span>
                                {phone}
                            </a>
                        </li>
                        <li>
                            <a href={`mailto:${email}`} className="contact-info">
                                <span className="material-symbols-outlined">
                                    email
                                </span>
                                {email}
                            </a>
                        </li>
                    </ul>

                </div>
            ) : null}
        </nav>
    )
}

if (document.getElementById('site-navs')) {
    const phone = document.head.getElementsByTagName('meta')['phone'].getAttribute('content');
    const email = document.head.getElementsByTagName('meta')['email'].getAttribute('content');
    ReactDOM.render(<Navs phone={phone} email={email} />, document.getElementById('site-navs'));
}
