import React from 'react'

export const useDestinations = () => {
    const options = [
        {
            label: 'Most Popular',
            options: [
                {
                    label: 'Caribbean',
                    value: 'caribbean'
                },
                {
                    label: 'Bahamas',
                    value: 'bahamas'
                },
                {
                    label: 'Mexico',
                    value: 'mexico'
                },
                {
                    label: 'Alaska',
                    value: 'alaska'
                },
                {
                    label: 'Europe',
                    value: 'europe'
                },
                {
                    label: 'Bermuda',
                    value: 'bermuda'
                },
                {
                    label: 'Hawaii',
                    value: 'hawaii'
                },
                {
                    label: 'Canada / New England',
                    value: 'canada-new-england'
                },
            ]
        },
        {
            label: 'Other Destinations',
            options: [
                {
                    label: 'Arctic / Antarctic',
                    value: 'arctic-antarctic'
                },
                {
                    label: 'Middle East',
                    value: 'middle-east'
                },
                {
                    label: 'Africa',
                    value: 'africa'
                },
                {
                    label: 'Panama Canal',
                    value: 'panama-canal'
                },
                {
                    label: 'Asia',
                    value: 'asia'
                },
                {
                    label: 'Pacific Coastal',
                    value: 'pacific-coastal'
                },
                {
                    label: 'Australia / New Zealand',
                    value: 'australia-new-zealand'
                },
                {
                    label: 'Central America',
                    value: 'central-america'
                },
                {
                    label: 'Galapagos',
                    value: 'galapagos'
                },
                {
                    label: 'Getaway at Sea',
                    value: 'getaway-at-sea'
                },
                {
                    label: 'Transatlantic',
                    value: 'transatlantic'
                },
                {
                    label: 'World',
                    value: 'world-cruise '
                },
                {
                    label: 'South America',
                    value: 'south-america'
                },
                {
                    label: 'South Pacific',
                    value: 'south-pacific '
                },
                {
                    label: 'Transpacific',
                    value: 'transpacific'
                },
            ]
        }        
    ];

    const kvOptions = () => {
        const kvo = [];
        for(let o of options) {
            for (let op of o.options) {
                kvo.push(op);
            }
        }
        return kvo;
    }

    return { options, kvOptions }
}