import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import Overview from './package/Overview';
import Pricing from './package/Pricing';
import Terms from './package/Terms';

const Package = ({ data }) => {
    const [activeTab, setActiveTab] = useState({});

    const tabs = [
        {
            id: 'tab-overview',
            label: 'Overview',
            component: <Overview data={data}/>
        },
        {
            id: 'tab-pricing',
            label: 'Dates & Prices',
            component: <Pricing data={data}/>
        },
        {
            id: 'tab-tnc',
            label: 'Terms & Conditions',
            component: <Terms data={data}/>
        },
    ];

    useEffect(() => {
        setActiveTab(tabs[0]);
    }, []);

    const handleClickTab = (e,id) => {
        e.preventDefault();

        const tab = tabs.find(x => x.id === id);
        setActiveTab(tab);
    }

    return (
        <React.Fragment>
            <div className='package-tabs tabs'>
                {tabs.map(t => (<a key={t.id} href='#' className={`tab ${activeTab.id === t.id ? 'active' : null }`} onClick={(e) => handleClickTab(e, t.id)}>{t.label}</a>))}
            </div>

            <div className='tab-content'>
                {activeTab.component}
            </div>
        </React.Fragment>
    )
}

if (document.getElementById('package-details')) {
    const data = document.getElementById('package-details').getAttribute('data-data');
    ReactDOM.render(<Package data={data} />, document.getElementById('package-details'));
}