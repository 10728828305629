import React, { useEffect, useState } from 'react'

const FormInput = (props) => {
    const [value, setValue] = useState("");
    const { id, type, label, formik, isRequired = false, placeholder='', isReadOnly = false } = props;

    useEffect(() => {
        setValue(formik.values[id]);
    },[formik.values[id]]);

    const handleChange = (el) => {
        formik.setFieldValue(id, el.target.value);
        setValue(el.target.value);
    }

    return (
        <div className='form-group'>
            <label htmlFor={id} className='form-label'>
                {label} {isRequired ? <span className='required'>*</span> : null}
            </label>
            <input type={typeof(type) === 'undefined' ? 'text' : type} id={id} className='form-control' value={value} onChange={handleChange} placeholder={placeholder} readOnly={isReadOnly} />
            {(Boolean(formik.touched[id]) && Boolean(formik.touched[id])) ? <span className='helper-text'>{formik.errors[id]}</span> : null}
        </div>
    )
}

export default FormInput;

