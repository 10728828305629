import React from 'react'

export const useInclusions = () => {
    const incOptions = [
        {
            value: 'dining',
            label: 'Dining',
            description: 'Main dining, buffet meals, and snacks'
        },
        {
            value: 'youth-programs',
            label: 'Youth Programs',
            description: 'Ages 3-17'
        },
        {
            value: 'drinks',
            label: 'Drinks',
            description: 'Water and non-specialty coffee and tea'
        },
        {
            value: 'fitness-facilities',
            label: 'Fitness facilities',
            description: 'Cardio and exercise equipment'
        },
        {
            value: 'onboard-entertainment',
            label: 'Onboard entertainment',
            description: 'Broadway-style shows and live music'
        },
        {
            value: 'room-service',
            label: 'Room service',
            description: '24-hour in-room dining option'
        },
        {
            value: 'spa-facilities',
            label: 'Spa facilities',
            description: 'Relaxing wellness spaces'
        },
    ];

    const kvOptions = () => {
        const kvo = [];

        for(let op of incOptions) {
            kvo.push({
                value: op.value,
                label: op.label
            });
        }

        return kvo;
    }

    return { incOptions, kvOptions }
}