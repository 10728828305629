import React, { useEffect, useState } from 'react'

const Activities = (props) => {
    const [included, setIncluded] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const [expectations, setExpectations] = useState([]);

    const { details } = props;

    useEffect(() => {
        setIncluded(details.inclusions ?? []);
        setExcluded(details.exclusions ?? []);
        setExpectations(details.expectations ?? []);
    },[]);

    return (
        <React.Fragment>
            <div className='activity-list'>
                <h3>What's included</h3>
                {included.length > 0 && included.map(i => (
                    <span className='grid-item' key={i}>
                        <span className='material-symbols-outlined'>check</span>
                        {i}
                    </span>
                ))}
            </div>

            <div className='activity-list'>
                <h3>What's not included</h3>
                {excluded.length > 0 && excluded.map(i => (
                    <span className='grid-item' key={i}>
                        <span className='material-symbols-outlined'>check</span>
                        {i}
                    </span>
                ))}
            </div>

            <div className='activity-list'>
                <h3>What can you expect on every trip</h3>
                {expectations.length > 0 && expectations.map(i => (
                    <span className='grid-item' key={i}>
                        <span className='material-symbols-outlined'>check</span>
                        {i}
                    </span>
                ))}
            </div>
        </React.Fragment>
    )
}

export default Activities