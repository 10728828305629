import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useCabins } from '../../cruises/hooks/useCabins';
import { groupBy } from '../../../helpers/Helper';
import CabinItem from './CabinItem';

const Cabin = ({data}) => {
    const [cabins, setCabins] = useState({});
    const { options } = useCabins();

    const order = options.map(o => o.value);

    useEffect(() => {
        if(data !== null) {
            const group = groupBy(data, (c) => c.cabin_type);
            const list = {};
            for(let o of order) {
               list[o] = group[o];
            }
            setCabins(list);
        }
    },[data]);

    return (
        <React.Fragment>
            <h3>Cabin experience</h3>
            <div className='cabin-items'>
            {Object.keys(cabins).length > 0 ? order.map((c) => (
                <React.Fragment key={c}>
                    {typeof(cabins[c]) !== 'undefined' ? <CabinItem type={options.find(x => x.value === c)} data={cabins[c]} /> : null}
                </React.Fragment>
            )) : null}
            </div>
        </React.Fragment>
    )
}

export default Cabin;