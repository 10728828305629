import React from 'react'

export const useAdditionals = () => {
    const addOptions = [
        {
            value: 'alcoholic-drinks',
            label: 'Alcoholic drinks',
            description: 'Beer, wine and spirits'
        },
        {
            value: 'wifi',
            label: 'WiFi',
            description: 'Stay connected on board'
        },
        {
            value: 'specialty-drinks',
            label: 'Specialty drinks',
            description: 'Specialty coffee and tea, soft drinks, juice, and bottled water'
        },
        {
            value: 'spa-treatments',
            label: 'Spa treatments',
            description: 'Massages and wellness treatments'
        },
        {
            value: 'fitness-classes',
            label: 'Fitness classes',
            description: 'Variety of instructor-led exercise sessions'
        },
        {
            value: 'gratuities',
            label: 'Gratuities',
            description: 'From $12 - $18 USD per person per day'
        },
        {
            value: 'specialty-dining',
            label: 'Specialty dining',
            description: 'À la carte restaurants, lounges, and cafes'
        },
        {
            value: 'shore-excursions',
            label: 'Shore excursions',
            description: 'Immersive port of call experiences'
        },
        {
            value: 'salon-services',
            label: 'Salon services',
            description: 'Hair care, manicures, and pedicures'
        },
        {
            value: 'laundry-services',
            label: 'Laundry services',
            description: 'Wash and press amenities'
        },
    ];

    const kvOptions = () => {
        const kvo = [];

        for(let op of addOptions) {
            kvo.push({
                value: op.value,
                label: op.label
            });
        }

        return kvo;
    }

    return { addOptions, kvOptions }
}