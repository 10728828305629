import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAdditionals } from '../../cruises/hooks/useAdditionals';

const Additional = ({ data }) => {
    const [additionals, setAdditional] = useState([]);
    const { addOptions } = useAdditionals();

    useEffect(() => {
        if (data !== null) {
            const add = data.split(',');
            const options = [];

            for (const i of add) {
                const option = addOptions.find(x => x.value === i);
                if (typeof (option) !== 'undefined') {
                    options.push(option);
                }
            }
            setAdditional(options);
        }
    }, [data]);

    return (
        <React.Fragment>
            <h3>Additional costs to consider</h3>

            <div className='grid-list'>
                {additionals.length > 0 && additionals.map(x => (
                    <div className='grid-item' key={x.value}>
                        <span className="material-symbols-outlined">
                            check
                        </span>
                        <div className='label-description'>
                            <h6>{x.label}</h6>
                            <p>{x.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

if (document.getElementById('cruise-additionals')) {
    const data = document.getElementById('cruise-additionals').getAttribute('data-additionals');
    ReactDOM.render(<Additional data={data} />, document.getElementById('cruise-additionals'));
}