import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",

        "& .ql-container": {
            minHeight: "200px",
            height: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column"
        },

        "& .ql-editor": {
            height: "100%",
            width: "100%",
            flex: 1,
            overflowY: "auto",
            fontSize: "0.95rem"
        }
    },
}));

const TextArea = ({id, value, onChange, label, hasError, errorMsg, rows = 6, isRequired = false}) => {
    const classes = useStyles();

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <div className={`form-group ${classes.root}`}>
            <label htmlFor={id} className='form-label'>
                {label} {isRequired ? <span className='required'>*</span> : null}
            </label>
            <textarea 
                id={id}
                value={value}
                onChange={handleChange}
                className='form-control'
                rows={rows}
            ></textarea>
            {hasError ? <p className="helper-text">{errorMsg}</p> : null}
        </div>
    )
}

export default TextArea